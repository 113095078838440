import React from 'react';
import Layout from '../components/Layout';
import Container from '../components/Container';
import PageTitle from '../components/PageTitle';
import { FAQJsonLd, GatsbySeo } from 'gatsby-plugin-next-seo';
import { graphql, StaticQuery } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import casinolistStyle from '../components/Casinolist.module.css';

class Nettikasinot extends React.Component {
  //LOAD MORE
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      visible: 20,
      error: false,
    };
    this.loadMore = this.loadMore.bind(this);
    this.showLess = this.showLess.bind(this);
  }

  loadMore() {
    this.setState(prev => {
      return { visible: prev.visible + 20 };
    });
  }
  showLess() {
    this.setState(prev => {
      return { visible: 20 };
    });
  }

  setListeners() {
    var lisa = document.getElementsByClassName('tab-lisa');
    var bonus = document.getElementsByClassName('tab-bonus');
    var maksu = document.getElementsByClassName('tab-maksu');
    var i;
    for (i = 0; i < lisa.length; i++) {
      if (lisa[i].dataset.listenerAdded) continue;
      lisa[i].addEventListener('click', function() {
        this.classList.toggle('active');
        if (
          this.parentNode.nextElementSibling.childNodes[0].style.display ===
          'block'
        ) {
          this.parentNode.nextElementSibling.childNodes[0].style.display =
            'none';
        } else {
          this.parentNode.nextElementSibling.childNodes[0].style.display =
            'block';
          this.parentNode.nextElementSibling.childNodes[1].style.display =
            'none';
          this.parentNode.nextElementSibling.childNodes[2].style.display =
            'none';
          this.nextElementSibling.classList.remove('active');
          this.nextElementSibling.nextElementSibling.classList.remove('active');
        }
      });
      lisa[i].dataset.listenerAdded = 'true';
    }
    var j;
    for (j = 0; j < bonus.length; j++) {
      if (bonus[j].dataset.listenerAdded) continue;
      bonus[j].addEventListener('click', function() {
        this.classList.toggle('active');
        if (
          this.parentNode.nextElementSibling.childNodes[1].style.display ===
          'block'
        ) {
          this.parentNode.nextElementSibling.childNodes[1].style.display =
            'none';
        } else {
          this.parentNode.nextElementSibling.childNodes[1].style.display =
            'block';
          this.parentNode.nextElementSibling.childNodes[0].style.display =
            'none';
          this.parentNode.nextElementSibling.childNodes[2].style.display =
            'none';
          this.nextElementSibling.classList.remove('active');
          this.previousElementSibling.classList.remove('active');
        }
      });
      bonus[j].dataset.listenerAdded = 'true';
    }
    var k;
    for (k = 0; k < maksu.length; k++) {
      if (maksu[k].dataset.listenerAdded) continue;
      maksu[k].addEventListener('click', function() {
        this.classList.toggle('active');
        if (
          this.parentNode.nextElementSibling.childNodes[2].style.display ===
          'block'
        ) {
          this.parentNode.nextElementSibling.childNodes[2].style.display =
            'none';
        } else {
          this.parentNode.nextElementSibling.childNodes[2].style.display =
            'block';
          this.parentNode.nextElementSibling.childNodes[0].style.display =
            'none';
          this.parentNode.nextElementSibling.childNodes[1].style.display =
            'none';
          this.previousElementSibling.classList.remove('active');
          this.previousElementSibling.previousElementSibling.classList.remove(
            'active'
          );
        }
      });
      maksu[k].dataset.listenerAdded = 'true';
    }
  }

  componentDidMount() {
    var links = document.links;
    for (var i = 0, linksLength = links.length; i < linksLength; i++) {
      if (links[i].hostname != window.location.hostname) {
        links[i].target = '_blank';
        links[i].rel = 'noopener';
      }
    }
    this.setListeners();
  }

  componentDidUpdate() {
    this.setListeners();
  }

  imageLinks = {
    Trustly: '/images/maksu/trustly.webp',
    Euteller: '/images/maksu/euteller.webp',
    Brite: '/images/maksu/brite.webp',
    Visa: '/images/maksu/visa.webp',
    'Visa Electron': '/images/maksu/visa-electron.webp',
    Skrill: '/images/maksu/skrill.webp',
    Mastercard: '/images/maksu/mastercard.webp',
    Maestro: '/images/maksu/maestro.webp',
    Neteller: '/images/maksu/neteller.webp',
    Paysafecard: '/images/maksu/paysafecard.webp',
    Zimpler: '/images/maksu/zimpler.webp',
  };

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            contentfulMoneysivujenOsiot(slug: { eq: "nettikasinot" }) {
              slug
              sivu
              ylosanTeksti {
                json
              }
              kasinolista {
                kasinonNimi
                kasinonListanimi
                bonus
                ilmaiskierrokset
                rahansiirto
                logonTiedostonimi
                listalogo {
                  title
                  file {
                    url
                  }
                }
                afflink
                kasinonLisatiedot {
                  json
                }
                bonustiedot {
                  json
                }
              }
              alaosanTeksti {
                json
              }
              alaosanTeksti2 {
                json
              }
              alaosanTeksti3 {
                json
              }
            }
          }
        `}
        render={data => (
          <Layout>
            <GatsbySeo
              title="Nettikasinot | Ultimaattinen katalogi! | Ei jätettä!"
              description="Ainoastaan kaikki nettikasinot jotka on laatutarkastettu ylläpidon toimesta. Valitse luotettava nettikasino ja unohda kompostiin joutavat jäteläjät!"
            />
            <FAQJsonLd
              questions={[
                {
                  question: 'Nettikasino jäädytti pelitilini? Miten toimin?',
                  answer:
                    'Laillisen pelilisenssin alaisuudessa toimivat nettikasinot eivät jäädytä asiakkaan pelitiliä ilman syytä. Aivan ensimmäiseksi selvitä kasinon asiakaspalvelun yhteystiedot ja tiedustele ongelmaa sieltä.',
                },
                {
                  question:
                    'Sain pyynnön lähettää kuvan passista nettikasinolle. Onko tämä turvallista?',
                  answer:
                    'Monet nettikasinot varmistavat asiakkaan henkilöllisyyden pyytämällä kuvaa tai skannausta passista ja esim. sähkölaskusta. Tämä on täysin normaali ja turvallinen käytäntö.',
                },
                {
                  question: 'Kuinka nopeasti nettikasinot maksavat voitot?',
                  answer:
                    'Rahansiirtojen nopeus on täysin kasinokohtaista. Laadukkaat nettikasinot saattavat maksaa voittosi jopa viidessä minuutissa, kun taas epämääräisemmät toimijat panttaavat voittojasi useita päiviä.',
                },
                {
                  question:
                    'Onko ulkomaisilla nettikasinoilla suomenkielinen asiakaspalvelu?',
                  answer:
                    'Nykyisin todella usea kasino tarjoaa suomenkielisen asiakaspalvelun. Kasinosivu.com suosii rankingissaan juurikin näitä kasinoita. Lue sivuiltamme lisätietoja suomi-nettikasinoista.',
                },
                {
                  question: 'Mikä on nettikasinoiden palautusprosentti?',
                  answer:
                    'Nettikasinoiden palautusprosentit vaihtelevat kasino- ja jopa pelikohtaisesti. Ulkomaisten kasinoiden palautusprosentit ovat poikkeuksetta parempia kuin kotimaisen Veikkauksen nettikasinon palautusprosentti. Muista kuitenkin, että palautusprosentti on aina alle 100%. Etu on kasinolla.',
                },
                {
                  question:
                    'Haluaisin jäädyttää/sulkea nettikasinon pelitilin, kuinka toimin?',
                  answer:
                    'Turvallisiksi testatuilla kansainvälisillä nettikasinoilla pelitilin jäädyttäminen tai sulkeminen on tehty erittäin helpoksi. Etsi valikoista Oma tili --> vastuullinen pelaaminen ja näin voit sulkea tai jäädyttää asiakastilisi joko kokonaan tai määräajaksi parilla klikkauksella.',
                },
              ]}
            />
            <Container>
              <PageTitle>{data.contentfulMoneysivujenOsiot.sivu}</PageTitle>
              <div className="ylaosa">
                {documentToReactComponents(
                  data.contentfulMoneysivujenOsiot.ylosanTeksti.json,
                  {
                    renderNode: {
                      [BLOCKS.EMBEDDED_ASSET]: node => (
                        <img
                          className="lazyload page-img"
                          data-src={`${node.data.target.fields.file['en-US'].url}`}
                          alt={node.data.target.fields.title['en-US']}
                        />
                      ),
                    },
                  }
                )}
              </div>
              <table>
                <tbody>
                  {data.contentfulMoneysivujenOsiot.kasinolista
                    .slice(0, this.state.visible)
                    .map(
                      ({
                        listalogo,
                        logonTiedostonimi,
                        kasinonListanimi,
                        bonus,
                        ilmaiskierrokset,
                        afflink,
                        kasinonLisatiedot,
                        bonustiedot,
                        rahansiirto,
                      }) => (
                        <tr
                          className={casinolistStyle.casino}
                          key={kasinonListanimi}
                        >
                          <td className={casinolistStyle.top}>
                            <div className={casinolistStyle.logo}>
                              <img
                                data-src={`/images/logo/${logonTiedostonimi}`}
                                alt={listalogo.title}
                                className="lazyload"
                                width="120"
                                height="50"
                              />
                              <div className={casinolistStyle.name}>
                                <span className={casinolistStyle.title}>
                                  <b>{kasinonListanimi}</b>
                                </span>
                              </div>
                            </div>
                            <div className={casinolistStyle.content}>
                              <div className={casinolistStyle.contentBox}>
                                <div className={casinolistStyle.contentInfo}>
                                  <div className={casinolistStyle.offerItem}>
                                    <i>Bonukset</i>
                                    <p>
                                      <b>{bonus}</b>
                                    </p>
                                  </div>
                                  <div className={casinolistStyle.offerItem}>
                                    <i>Ilmaiskierrokset</i>
                                    <p>
                                      <b>{ilmaiskierrokset}</b>
                                    </p>
                                  </div>
                                </div>
                                <div className={casinolistStyle.contentBtn}>
                                  <a
                                    className={casinolistStyle.btn}
                                    href={afflink}
                                    target="_blank"
                                  >
                                    Pelaa heti!
                                  </a>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className={casinolistStyle.bottom}>
                            <div className={casinolistStyle.expand}>
                              <div
                                className={`${casinolistStyle.expandTabs} tab-lisa`}
                              >
                                <span>Lisätiedot</span>
                              </div>
                              <div
                                className={`${casinolistStyle.expandTabs} tab-bonus`}
                              >
                                <span>Bonustiedot</span>
                              </div>
                              <div
                                className={`${casinolistStyle.expandTabs} tab-maksu`}
                              >
                                <span>Maksutavat</span>
                              </div>
                            </div>
                            <div className={casinolistStyle.expandContent}>
                              <div
                                className={`${casinolistStyle.expandInfo} lisa`}
                              >
                                {documentToReactComponents(
                                  kasinonLisatiedot.json
                                )}
                              </div>
                              <div
                                className={`${casinolistStyle.expandInfo} bonus`}
                              >
                                {documentToReactComponents(bonustiedot.json)}
                              </div>
                              <div
                                className={`${[
                                  casinolistStyle.expandInfo,
                                  casinolistStyle.expandMaksu,
                                ].join(' ')} maksu`}
                              >
                                <span>Talletus- ja kotiutustavat: </span>
                                <div className="talletus">
                                  {rahansiirto.map((raha, index) => (
                                    <img
                                      key={raha + index}
                                      src={this.imageLinks[raha]}
                                      alt={raha}
                                      className="lazyload"
                                    />
                                  ))}
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )
                    )}
                </tbody>
              </table>
              <div className="loadBtn">
                {this.state.visible > 20 && (
                  <button onClick={this.showLess} type="button" id="showLess">
                    Vähemmän
                  </button>
                )}
                {this.state.visible <
                  data.contentfulMoneysivujenOsiot.kasinolista.length && (
                  <button onClick={this.loadMore} type="button" id="loadMore">
                    Näytä lisää
                  </button>
                )}
              </div>
              <div>
                <h3>Näitä kysytään meiltä usein:</h3>
                <p>
                  Nettikasinolla pelatessa saattaa syntyä ongelmatilanteita.
                  Alla näkyviä kysymyksiä meiltä tiedustellaan usein.
                </p>
                <div>
                  <div>
                    <h4>Nettikasino jäädytti pelitilini? Miten toimin? </h4>
                  </div>
                  <p>
                    Laillisen pelilisenssin alaisuudessa toimivat nettikasinot
                    eivät jäädytä asiakkaan pelitiliä ilman syytä. Aivan
                    ensimmäiseksi selvitä kasinon asiakaspalvelun yhteystiedot
                    ja tiedustele ongelmaa sieltä.
                  </p>
                </div>
                <div>
                  <div>
                    <h4>
                      Sain pyynnön lähettää kuvan passista nettikasinolle. Onko
                      tämä turvallista?{' '}
                    </h4>
                  </div>
                  <p>
                    Monet nettikasinot varmistavat asiakkaan henkilöllisyyden
                    pyytämällä kuvaa tai skannausta passista ja esim.
                    sähkölaskusta. Tämä on täysin normaali ja turvallinen
                    käytäntö.
                  </p>
                </div>
                <div>
                  <div>
                    <h4>Kuinka nopeasti nettikasinot maksavat voitot?</h4>
                  </div>
                  <p>
                    Rahansiirtojen nopeus on täysin kasinokohtaista. Laadukkaat
                    nettikasinot saattavat maksaa voittosi jopa viidessä
                    minuutissa, kun taas epämääräisemmät toimijat panttaavat
                    voittojasi useita päiviä.
                  </p>
                </div>
                <div>
                  <div>
                    <h4>
                      Onko ulkomaisilla nettikasinoilla suomenkielinen
                      asiakaspalvelu?{' '}
                    </h4>
                  </div>
                  <p>
                    Nykyisin todella usea kasino tarjoaa suomenkielisen
                    asiakaspalvelun. Kasinosivu.com suosii rankingissaan
                    juurikin näitä kasinoita. Lue sivuiltamme lisätietoja
                    suomi-nettikasinoista.
                  </p>
                </div>
                <div>
                  <div>
                    <h4>Mikä on nettikasinoiden palautusprosentti? </h4>
                  </div>
                  <p>
                    Nettikasinoiden palautusprosentit vaihtelevat kasino- ja
                    jopa pelikohtaisesti. Ulkomaisten kasinoiden
                    palautusprosentit ovat poikkeuksetta parempia kuin
                    kotimaisen Veikkauksen nettikasinon palautusprosentti.
                    Muista kuitenkin, että palautusprosentti on aina alle 100%.
                    Etu on kasinolla.
                  </p>
                </div>
                <div>
                  <div>
                    <h4>
                      Haluaisin jäädyttää/sulkea nettikasinon pelitilin, kuinka
                      toimin?{' '}
                    </h4>
                  </div>
                  <p>
                    Turvallisiksi testatuilla kansainvälisillä nettikasinoilla
                    pelitilin jäädyttäminen tai sulkeminen on tehty erittäin
                    helpoksi. Etsi valikoista Oma tili -- vastuullinen
                    pelaaminen ja näin voit sulkea tai jäädyttää asiakastilisi
                    joko kokonaan tai määräajaksi parilla klikkauksella.
                  </p>
                </div>
              </div>
              <div className="alaosa">
                <img
                  src="/images/nettikasinot.webp"
                  alt="Nettikasinot"
                  title="Valitse korkealaatuinen nettikasino"
                  class="lazyload"
                  className="wrapperImage"
                  width="300"
                  height="286"
                />
                {documentToReactComponents(
                  data.contentfulMoneysivujenOsiot.alaosanTeksti.json,
                  {
                    renderNode: {
                      [BLOCKS.EMBEDDED_ASSET]: node => (
                        <img
                          className="lazyload page-img"
                          data-src={`${node.data.target.fields.file['en-US'].url}`}
                          alt={node.data.target.fields.title['en-US']}
                        />
                      ),
                    },
                  }
                )}
              </div>
              <div className="alaosa2">
                {documentToReactComponents(
                  data.contentfulMoneysivujenOsiot.alaosanTeksti2.json,
                  {
                    renderNode: {
                      [BLOCKS.EMBEDDED_ASSET]: node => (
                        <img
                          className="lazyload page-img"
                          data-src={`${node.data.target.fields.file['en-US'].url}`}
                          alt={node.data.target.fields.title['en-US']}
                        />
                      ),
                    },
                  }
                )}
              </div>
              <div className="alaosa2">
                {documentToReactComponents(
                  data.contentfulMoneysivujenOsiot.alaosanTeksti3.json,
                  {
                    renderNode: {
                      [BLOCKS.EMBEDDED_ASSET]: node => (
                        <img
                          className="lazyload page-img"
                          data-src={`${node.data.target.fields.file['en-US'].url}`}
                          alt={node.data.target.fields.title['en-US']}
                        />
                      ),
                    },
                  }
                )}
              </div>
            </Container>
          </Layout>
        )}
      />
    );
  }
}

export default Nettikasinot;
